import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography } from "@mui/material";
import IAttributesForm from "./interface";
import TextField from "@atoms/TextField";
import SwitchMolecule from "@molecules/Switch";
import { useFieldArray } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { t } from "@lingui/macro";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import { COLORS } from "@theme/Colors";
import AsyncSelectMolecule from "@molecules/AsyncSelect";
import DateFieldMolecule from "@molecules/DateField";

const AttributesFormGroupMolecule = ({
  group,
  formControl,
  patch,
  showTitle,
  personAssignedGroup,
  formInstance,
}: IAttributesForm) => {
  const groupName = group.alias ? group.alias : "group-alias";

  const [hasToggle /*, setHasToggle*/] = useState<any>(
    group.attributes.find((field: any) => field.name === "enabled")
      ? true
      : false,
  );
  const [otherVisible, setOtherVisible] = useState<boolean>(false);

  const toggleOn = formInstance?.watch(
    `${groupName}.${0}.${group.attributes.find(
      (field: any) => field.name === "enabled",
    )?.id}`,
  );

  const gridItemSize = 3; /*Math.ceil(12 / (patch ? group.attributes.length : group.attributes.length))*/

  const { fields, append, remove } = useFieldArray({
    control: formControl,
    name: groupName, // unique name for your Field Array
  });

  const groupSelectWatcher = formInstance.watch(
    `${groupName}.0.${group.attributes.find((attr: any) => attr.is_filter)
      ?.id}`,
  );

  useEffect(() => {
    group.attributes.forEach((attribute: any) => {
      if (attribute.options) {
        const selectWithOthers = attribute.options.find(
          (option: any) => option.label === "Other",
        );
        if (selectWithOthers) {
          if (groupSelectWatcher === selectWithOthers.value) {
            setOtherVisible(true);
          } else {
            setOtherVisible(false);
            const fieldDescription = group.attributes.find(
              (field: any) => field.name === "other_description",
            );
            if (fieldDescription) {
              formInstance.setValue(
                `${groupName}.${0}`,
                {
                  ...formInstance.getValues(`${groupName}.0`),
                  ...{ [`${fieldDescription.id}`]: "" },
                },
                { shouldDirty: false },
              );
              // updateField(0, {...formInstance.getValues(`${groupName}.0`), ...{[`${fieldDescription.id}`]: ''}});
            }
          }
        }
      }
    });
  }, [groupSelectWatcher, formInstance, group.attributes, groupName]);

  useEffect(() => {
    if (toggleOn === false) {
      fields.forEach((field: any, fieldIndex: number) => {
        formInstance.setValue(
          `${groupName}.${fieldIndex}`,
          {},
          { shouldDirty: false },
        );
        // updateField(fieldIndex, {});
      });
    }
  }, [toggleOn]);

  const reduceAttributes = (attributes: Array<any>, setDefaultValue = true) =>
    attributes.reduce((prevValue: any, currValue: any) => {
      const object = { ...prevValue };
      object[currValue.attribute_id ? currValue.attribute_id : currValue.id] =
        setDefaultValue ? currValue.value : ""; // default value
      return object;
    }, {});

  const appendFields = () => {
    let fieldsObjectList: any = {};
    if (patch && personAssignedGroup) {
      if (personAssignedGroup.length) {
        fieldsObjectList = [];
        personAssignedGroup.forEach((attribute: any, indexA: number) => {
          fieldsObjectList.push(reduceAttributes(attribute));
        });
      } else {
        fieldsObjectList = reduceAttributes(group.attributes);
      }
    } else {
      fieldsObjectList = reduceAttributes(group.attributes);
    }
    append(fieldsObjectList);
  };

  const appendFieldsRow = () => {
    append(
      reduceAttributes(patch ? group.attributes : group.attributes, false),
    );
  };

  useEffect(() => {
    if (patch) {
      appendFields();
    }
  }, [group]);

  /**
   * Attribute grid form input
   * @param attributesArray
   * @param fieldIndex
   * @returns
   */
  const Attributes = (
    attributesArray: Array<any>,
    fieldIndex: string | number,
  ) => {
    return attributesArray.map((attribute: any, attIndex: number) => {
      if (
        attribute.name !== "other_description" ||
        (attribute.name === "other_description" && otherVisible)
      ) {
        return (
          <Grid key={attIndex} item md={gridItemSize}>
            <Box mt={1} key={attIndex}>
              {(attribute.type.alias === "text" && toggleOn && hasToggle) ||
              (attribute.type.alias === "text" && !hasToggle) ? (
                <TextField
                  label={attribute.label}
                  controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
                  required={attribute.is_required}
                />
              ) : attribute.type.alias === "boolean" ? (
                <SwitchMolecule
                  label={attribute.label != 'enabled' ? attribute.label : ''}
                  control={formControl}
                  controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
                />
              ) : (attribute.type.alias === "select" &&
                  toggleOn &&
                  hasToggle) ||
                (attribute.type.alias === "select" && !hasToggle) ? (
                <AsyncSelectMolecule
                  listId={`${groupName}.${fieldIndex}.${attribute.id}`}
                  control={formControl}
                  controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
                  optionValue={"value"}
                  variant={"outlined"}
                  label={attribute.label}
                  filters={{ data: attribute.options }}
                  optionLabel={"label"}
                ></AsyncSelectMolecule>
              ) : (attribute.type.alias === "date" && toggleOn && hasToggle) ||
                (attribute.type.alias === "date" && !hasToggle) ? (
                <DateFieldMolecule
                  control={formControl}
                  controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
                  inputFormat={"dd/MM/yyyy"}
                  label={attribute.label}
                ></DateFieldMolecule>
              ) : (attribute.type.alias === "checkbox" &&
                  toggleOn &&
                  hasToggle) ||
                (attribute.type.alias === "checkbox" && !hasToggle) ? (
                <AsyncSelectMolecule
                  nullable={false}
                  listId={`${groupName}.${fieldIndex}.${attribute.id}`}
                  control={formControl}
                  emptyValue={[]}
                  multiple={true}
                  controlName={`${groupName}.${fieldIndex}.${attribute.id}`}
                  optionValue={"value"}
                  variant={"outlined"}
                  label={attribute.label}
                  filters={{ data: attribute.options }}
                  optionLabel={"label"}
                ></AsyncSelectMolecule>
              ) : (
                <Box key={attIndex}></Box>
              )}
            </Box>
          </Grid>
        );
      } else return <></>;
    });
  };

  const Items = (group: any) => {
    return (
      <React.Fragment>
        {fields.map((field: any, fieldIndex: number) => {
          return (
            <Box mb={1} key={fieldIndex}>
              <Grid container>
                <Grid
                  item
                  md={group.is_repeater /*&& fields.length > 1*/ ? 11 : 12}
                >
                  {
                    <Grid container spacing={1} key={fieldIndex}>
                      {Attributes(
                        patch ? group.attributes : group.attributes,
                        fieldIndex,
                      )}
                    </Grid>
                  }
                </Grid>
                {group.is_repeater /*&& fields.length > 1*/ ? (
                  <Grid item md={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Button onClick={() => remove(fieldIndex)}>
                        <RemoveCircleIcon color={"error"} />
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <Box>
      <React.Fragment>
        <Box mb={1}>
          {showTitle ? (
            <Typography
              color={COLORS.blue.primary}
              variant="body1"
              sx={{ marginBottom: "10px", marginTop: "25px" }}
            >
              {group.name}
            </Typography>
          ) : null}
          {Items(group)}
          {
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {group.is_repeater ? (
                <Box mt={1} sx={{ textAlign: "right" }}>
                  <Tooltip
                    placement="top"
                    title={t`Add a new ${group.name.toLowerCase()} detail`}
                  >
                    <Button
                      variant="contained"
                      color={"success"}
                      onClick={appendFieldsRow}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddCircleIcon />
                      <Typography>{`Add ${group.name.toLowerCase()} detail`}</Typography>
                    </Button>
                  </Tooltip>
                </Box>
              ) : null}
            </Box>
          }
        </Box>
      </React.Fragment>
    </Box>
  );
};

AttributesFormGroupMolecule.defaultProps = {
  showTitle: false,
};

export default AttributesFormGroupMolecule;
